import React from "react";
import EmptyLayout from "layouts/EmptyLayout";
import Icon from "components/Common/Icon";
import CardIcon from "components/Cards/CardIcon";
import CardImage from "components/Cards/CardImage";

export default function Page() {
  return (
    <EmptyLayout>
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1516110833967-0b5716ca1387?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1267&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-background"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-foreground font-semibold text-5xl">
                    Automation Test Robot
                  </h1>
                  <p className="mt-4 text-lg text-foreground-200">
                    Without any more coding, you can automate testing your
                    UiPath projects. This project enables test driven
                    development in UiPath.
                    <a
                      href="https://uipath.thangiswho.com/getting-started/"
                      target="_blank"
                      className="ml-2"
                    >
                      View on UiPath{" "}
                      <Icon
                        icon="external-link-alt"
                        className="flex-initial ml-1"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-16"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-foreground-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section
          id="features"
          className="pb-20 -mt-48 bg-background-300 text-foreground-300"
        >
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="md:w-4/12 lg:pt-12 p-4">
                <CardIcon
                  title="Generating test cases"
                  summary={`Automate generating test cases for each .xaml component,
                  then you can input your test data in excel.`}
                  icon="file-excel"
                  iconClassName="bg-primary-300 text-foreground-300"
                  className="bg-background-800 text-foreground-800"
                />
              </div>
              <div className="md:w-4/12 p-4">
                <CardIcon
                  title="Running test cases"
                  summary={`Automate running all your test cases and reporting results.`}
                  icon="sync"
                  iconClassName="bg-primary-300 text-foreground-300"
                  className="bg-background-800 text-foreground-800"
                />
              </div>
              <div className="md:w-4/12 lg:pt-6 p-4">
                <CardIcon
                  title="Its own logs"
                  summary={`Generate its own log files (besides UiPath Robot log), 
                  for furthur analyzing test result in CICD in the future version`}
                  icon="file-code"
                  iconClassName="bg-primary-300 text-foreground-300"
                  className="bg-background-800 text-foreground-800"
                />
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-16">
              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <h3 className="text-3xl mb-2 font-semibold leading-normal flex">
                  <Icon
                    icon="lightbulb"
                    cover={true}
                    diameter={12}
                    className="flex-initial mr-3 bg-primary-300"
                  />
                  Inspiration
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4">
                  Testing in UiPath was horrible as you have to manually edit
                  your (in/out) arguments every time, then click RUN button.
                  While most of programing languages provide testing frameworks
                  and CICD approach, workflow-style UiPath codes are lacking of
                  these. My objective is to qualify UiPath codes by enabling
                  automated tests and CICD approach.
                </p>
              </div>

              <div className="p-2 mr-auto ml-auto md:w-8/12">
                <CardImage
                  title="Test cases sample"
                  content={`You input your test data with In/Out arguments in excel.`}
                  image="https://uipath.thangiswho.com/assets/images/TestCase.png"
                  imageTitle="services"
                  polygon={true}
                  polygonColor="gray-200"
                  className="bg-background-800 text-foreground-800"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="video" className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-foreground-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <h3 className="text-3xl mb-8 px-4 font-semibold leading-normal flex">
                <Icon
                  icon="video"
                  cover={true}
                  diameter={12}
                  className="flex-initial mr-3 bg-primary-300"
                />
                Video
              </h3>
              <div className="w-full ml-auto mr-auto px-4">
                <div className="content-center">
                  <iframe
                    className="object-contain w-full"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/MU4K0wZDEUQ"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="getting-started"
          className="pb-20 relative block bg-gray-700"
        >
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-700 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Build something
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4">
                  Put the potentially record low maximum sea ice extent tihs
                  year down to low ice. According to the National Oceanic and
                  Atmospheric Administration, Ted, Scambos.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center">
                <Icon
                  icon="medal"
                  cover={true}
                  diameter={12}
                  className="text-xl bg-primary-500"
                />
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Excelent Services
                </h6>
                <p className="mt-2 mb-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <Icon
                  icon="poll"
                  cover={true}
                  diameter={12}
                  className="text-xl bg-primary-500"
                />
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Grow your market
                </h5>
                <p className="mt-2 mb-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center">
                <Icon
                  icon="lightbulb"
                  cover={true}
                  diameter={12}
                  className="text-xl bg-primary-500"
                />
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Launch time
                </h5>
                <p className="mt-2 mb-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 bg-gray-900">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-background-400 text-foreground-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Want to work with us?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4">
                      Complete this form and we will get back to you in 24
                      hours.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Full Name"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Message
                      </label>
                      <textarea
                        rows={4}
                        cols={80}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Type a message..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </EmptyLayout>
  );
}
