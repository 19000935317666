import React from "react";
import Icon from "components/Common/Icon";

type Props = {
  title: string;
  summary: string;
  className?: string;
  icon: string;
  iconClassName?: string;
};

export default function Card({
  title,
  summary,
  className,
  icon,
  iconClassName,
}: Props) {
  return (
    <div
      className={`relative flex flex-col min-w-0 w-full text-center m-4 break-words shadow-lg rounded-lg ${className}`}
    >
      <div className="px-4 py-5 flex-auto">
        <Icon
          icon={icon}
          cover={true}
          diameter={12}
          className={iconClassName}
        />
        <h6 className="text-xl capitalize font-semibold">{title}</h6>
        <p className="mt-2 mb-4">{summary}</p>
      </div>
    </div>
  );
}
